import { selector } from "recoil";
import { cartAtom } from "./cart";

export const cartCounterSelector = selector({
    key: "cartCounterSelector",
    get: ({ get }) => {
        const cart = get(cartAtom);

        return cart?.lines?.reduce((acc, item) => acc + item.quantity, 0) ?? 0;
    }
});
