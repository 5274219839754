import { useRef, useEffect } from "react";

export default function usePhysicalScanner(onResult = () => {}) {
    const characters = useRef("");

    useEffect(() => {
        characters.current = "";

        const handleDocumentKeyPress = async (e) => {
            setTimeout(() => {
                characters.current = "";
            }, 75);

            if (e.key !== "Enter") {
                characters.current += e.key;
            } else if (characters.current?.length > 5) {
                onResult(characters.current);
                characters.current = "";
            }
        };

        document.addEventListener("keypress", handleDocumentKeyPress);

        return () => document.removeEventListener("keypress", handleDocumentKeyPress);
    }, [onResult]);
}
