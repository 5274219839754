import { cookie } from "@divide/ois-react-framework/common";
import { parseJwt } from "@divide/ois-react-framework/helpers";

const retailApiClient = {
    get: async (url = "", bearerToken = "", requestOptions = {}) => {
        const options = {
            method: "get",
            headers: getRetailApiHeaders(bearerToken, requestOptions)
        };

        return await request(url, options);
    },

    post: async (url = "", payload = {}, bearerToken = "", requestOptions = {}) => {
        const options = {
            method: "post",
            headers: getRetailApiHeaders(bearerToken, requestOptions),
            body: formatData(payload)
        };

        return await request(url, options);
    },

    put: async (url = "", payload = {}, bearerToken = "", requestOptions = {}) => {
        const options = {
            method: "put",
            headers: getRetailApiHeaders(bearerToken, requestOptions),
            body: formatData(payload)
        };

        return await request(url, options);
    },

    delete: async (url = "", payload = {}, bearerToken = "", requestOptions = {}) => {
        const options = {
            method: "delete",
            headers: getRetailApiHeaders(bearerToken, requestOptions),
            body: formatData(payload)
        };

        return await request(url, options);
    }
};

export function getRetailApiHeaders(bearerToken, requestOptions = {}) {
    let payload = parseJwt(bearerToken);
    let cultureCode = cookie.get("rs_culture-code") ?? "nl-NL";

    let headers = {
        "Content-Type": "application/json",
        "x-siteid": 1,
        "Accept-Language": cultureCode,
        "Accept-Encoding": "gzip"
    };

    if (bearerToken) {
        headers["Authorization"] = `Bearer ${bearerToken}`;
    }

    if (payload.scope.includes("admin")) {
        const cookieTenant = cookie.get("rs_tenant");
        headers["x-tenant"] = cookieTenant || cookie.get("rs_tenant");
    }

    if (requestOptions.headers) {
        headers = {
            ...headers,
            ...requestOptions.headers
        };
    }

    return headers;
}

function formatData(data) {
    return JSON.stringify(data, (_key, value) => {
        return typeof value === "string" ? value.trim() : value;
    });
}

async function request(url, options) {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then((response) => {
                if (response.status >= 500) {
                    throw new Error();
                } else if (response.status === 204) {
                    resolve(response);
                } else {
                    return responseType(response);
                }
            })
            .then((data) => {
                if (data) {
                    if (successfulStatus(data?.meta?.statusCode)) {
                        resolve(data);
                    } else {
                        throw new Error(data?.error?.message);
                    }
                }
            })
            .catch((error) => {
                return reject(error);
            });
    });
}

async function responseType(response) {
    const contentType = response.headers.get("content-type");
    const contentDisposition = response.headers.get("content-disposition");

    if (contentType?.includes("json")) {
        return await response.json();
    } else if (contentDisposition?.includes("attachment") || (contentType && ["pdf", "blob"].some((blobType) => contentType.includes(blobType)))) {
        return await response.blob();
    } else {
        return await response.text();
    }
}

export function successfulStatus(statusCode) {
    return statusCode >= 200 && statusCode < 300;
}

export function redirectionStatus(statusCode) {
    return statusCode >= 300 && statusCode < 400;
}

export function clientErrorStatus(statusCode) {
    return statusCode >= 400 && statusCode < 499;
}

export default retailApiClient;
