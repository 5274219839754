import { createContext, useState } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslations, useResolveRoutesByAuthentication } from "@divide/ois-react-hooks";
import { useAuthentication } from "@divide/ois-react-components";
import { useCultureCode, useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess, useGetUserBranch, useIntervalReset } from "@divide/retailsuite-react-hooks";
import { Loader } from "@divide/retailsuite-react-components";
import { privateRoutes, publicRoutes } from "constants/routes"
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import useGetAllData from "hooks/general/useGetAllData";
import useValidateCartStorage from "hooks/general/useValidateCartStorage";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import useSetWebshopCodeToStorage from "hooks/general/useSetWebshopCodeToStorage";
import useProductBarcodeScan from "hooks/general/useProductBarcodeScan";
import useSetShowOnStockProductsToStorage from "hooks/general/useSetShowOnStockProductsToStorage";
import useCustomerStore from "hooks/store/useCustomerStore";
import useConditionsStore from "hooks/store/useConditionsStore";
// import useCartStore from "hooks/store/useCartStore";
import "./App.scss";
import CatalogSearchDialog from "parts/CatalogSearchDialog";
import usePhysicalProductScanner from "hooks/general/usePhysicalProductScanner";

export const AppContext = createContext();

export function App() {
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const { loading, isAuthenticated } = useAuthentication();
    const { routes, element } = useResolveRoutesByAuthentication(useAuthentication, useRoutes, privateRoutes, publicRoutes);

    const webshopSettings = useGetSettingsGroup(useAuthentication, appConfig, "Webshop");
    const productDisplaySettings = useGetSettingsGroup(useAuthentication, appConfig, "ProductDisplay");
    const generalConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "General");
    const dsaConfiguration = useGetConfigurationGroup(useAuthentication, appConfig, "DSA");
    const userBranch = useGetUserBranch(useAuthentication, appConfig);

    useValidateCartStorage(dsaConfiguration);
    useSetCdnDomainToStorage(webshopSettings.CdnDomain);
    useSetWebshopCodeToStorage(userBranch, dsaConfiguration.DefaultWebshopCodeDsa);
    useSetShowOnStockProductsToStorage(dsaConfiguration.ShowProductsWithoutStock);
    useIntervalReset(appConfig, storageKeys, dsaConfiguration.DataRefreshIntervalInMinutes);
    const physicalScanIsFetching = usePhysicalProductScanner(translation, dsaConfiguration.RetailApiEnabled);

    const { dataFetched, loadingText } = useGetAllData(translation, dsaConfiguration);
    const handleProductBarcodeScanResult = useProductBarcodeScan(translation, dsaConfiguration);
    const userAccess = useGetUserHasAccess(useAuthentication, 1);

    const [cartAmount, setCartAmount] = useState(0);
    const [appLoading, setAppLoading] = useState(false);
    const [catalogSearchTerm, setCatalogSearchTerm] = useState();
    const [catalogSearchActive, setCatalogSearchActive] = useState(false);
    const [catalogBarcodeResponse, setCatalogBarcodeResponse] = useState();
    const [cachedCatalog, setCachedCatalog] = useState();
    const [cachedCatalogMaps, setCachedCatalogMaps] = useState();
    const [currentSort, setCurrentSort] = useState();
    const [priceByProductIds, setPriceByProductIds] = useState();
    const [panelOpen, setPanelOpen] = useState(false);
    
    useCustomerStore();
    useConditionsStore();
   
    return (loading || !dsaConfiguration) ? (
        <Loader fullscreen={true} />
    ) : (
        <AppContext.Provider value={{ 
            webshopSettings,
            productDisplaySettings,
            generalConfiguration,
            dsaConfiguration,
            cultureCode, 
            setCultureCode, 
            translation,
            userAccess,
            userBranch,
            handleProductBarcodeScanResult,
            appLoading, 
            setAppLoading,
            catalogSearchTerm, 
            setCatalogSearchTerm,
            catalogSearchActive, 
            setCatalogSearchActive,
            catalogBarcodeResponse, 
            setCatalogBarcodeResponse,
            cachedCatalog, 
            setCachedCatalog,
            cachedCatalogMaps, 
            setCachedCatalogMaps,
            currentSort, 
            setCurrentSort,
            priceByProductIds, 
            setPriceByProductIds,
            cartAmount, 
            setCartAmount,
            panelOpen, 
            setPanelOpen,
            routes
        }}>
            {(!dataFetched && isAuthenticated) ? (
                <Loader fullscreen={true} text={loadingText} />  
            ) : (
                <>
                    {(appLoading || physicalScanIsFetching) && (
                        <Loader fullscreen={true} alpha={3} />
                    )}

                    {(isAuthenticated && dsaConfiguration["RetailApiEnabled"] === "true") && (
                        <CatalogSearchDialog />
                    )}
                    
                    {element}
                </>
            )}
        </AppContext.Provider>
    );
}
