import { lazy, Suspense } from "react";

const NotFoundPage = lazy(() => import("pages/NotFoundPage"));

export const sharedRoutes = [
    {
        path: "*",
        element: <Suspense fallback={<></>}><NotFoundPage /></Suspense>,
        children: [],
    }
];