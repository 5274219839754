import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { SearchIcon, Dialog, Button } from "@divide/retailsuite-react-components";
import catalogSearchActiveAtom from "store/catalogSearchActive";
import catalogSearchResetAtom from "store/catalogSearchReset";
import { AppContext } from "parts/App";
import styles from "./CatalogSearchDialog.module.scss";

export function CatalogSearchDialog () {
    const location = useLocation();
    const navigate = useNavigate();
    const { translation } = useContext(AppContext);
    const [catalogSearchActive, setCatalogSearchActive] = useRecoilState(catalogSearchActiveAtom);
    const catalogSearchReset = useRecoilValue(catalogSearchResetAtom);
    
    const [showInfo, setShowInfo] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue("");
    }, [catalogSearchReset])

    const getProductOverviewUrl = () => {
        const { search } = location;
        const params = new URLSearchParams(search);
        let searchUrl = new URL(window.location.origin);

        searchUrl.searchParams.set("page", "1");

        const remainingRequiredParameters = [
            { name: "limit", default: "12"}, 
            { name: "sort", default: "name" }, 
            { name: "stock", default: "inStock"},
            { name: "type", default: "all" }
        ];
        
        const optionalParameters = ["category", "filters", "minPrice", "maxPrice"];

        for (const parameter of remainingRequiredParameters) {
            if (params.has(parameter.name)) {
                searchUrl.searchParams.set(parameter.name, params.get(parameter.name));
            } else {
                searchUrl.searchParams.set(parameter.name, parameter.default);
            }
        }

        for (const parameter of optionalParameters) {
            if (params.has(parameter)) {
                searchUrl.searchParams.set(parameter, params.get(parameter));
            }
        }

        searchUrl.searchParams.set("search", value);

        return `${searchUrl.pathname}${searchUrl.search}`;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const url = getProductOverviewUrl();

        navigate(url);
        setCatalogSearchActive(false);
    }

    const inputRef = useRef();

    const handleReset = () => {
        setValue("");
        inputRef.current.focus();
    }

    const handleCloseInfo = () => {
        setShowInfo(false);
        inputRef.current.focus();
    }

    return (
        <Dialog
            open={catalogSearchActive}
            onClose={() => setCatalogSearchActive(false)}
            variant="popup"
            focusTrapped={true}
            closeOnOverlayClick={true}
            showCloseIcon={false}
            center={false}
            className={styles["dialog"]}
            animationDuration={100}>
            <div className={styles["wrapper"]}>
                <form className={styles["form"]} onSubmit={handleSubmit}>
                    <span className={styles["icon"]}><SearchIcon /></span>
                    
                    <input 
                        ref={inputRef}
                        type="text" 
                        placeholder={`${translation("global.searchForProduct")}...`} 
                        value={value} 
                        onChange={(e) => setValue(e.currentTarget.value)}/>

                    {value.length > 0 && (
                        <Button 
                            className={styles["additional-button"]} 
                            shape="circle"
                            color="tertiary"
                            variant="text"
                            size="small"
                            onClick={handleReset}
                            icon={<FontAwesomeIcon icon={faClose} />}/>
                    )}

                    <Button 
                        className={styles["additional-button"]} 
                        shape="circle"
                        color="tertiary"
                        variant="text"
                        size="small"
                        onClick={() => setShowInfo(true)}
                        icon={<FontAwesomeIcon icon={faCircleQuestion} />}/> 
                    
                    <Button type="submit" className={styles["submit"]} size="small" disabled={value.length === 0}>
                        {translation("global.search")}
                    </Button>
                </form>
                
                <Button 
                    className={styles["close"]} 
                    shape="circle"
                    color="tertiary"
                    icon={<FontAwesomeIcon icon={faClose} />}
                    onClick={() => setCatalogSearchActive(false)} />
            </div>
                    
            <Dialog 
                size="small"
                className={styles["info-dialog"]}
                center={false}
                animationDuration={100}
                open={showInfo}
                focusTrapped={false}
                onClose={handleCloseInfo}
                variant="popup">
                <Dialog.Header>
                    <FontAwesomeIcon icon={faCircleQuestion} />&nbsp;&nbsp;
                    {translation("global.searchInfo")}
                </Dialog.Header>
                <Dialog.Content className={styles["info-dialog-content"]}>
                    <p>{translation("messages.info.productsSearchInfo")}</p>
                    <p>{translation("messages.info.searchForFollowingProductProperties")}</p>
                    <ul>
                        <li>{translation("messages.info.searchableProperties.name")}</li>
                        <li>{translation("messages.info.searchableProperties.brand")}</li>
                        <li>{translation("messages.info.searchableProperties.barcode")}</li>
                        <li>{translation("messages.info.searchableProperties.articleNumber")}</li>
                        <li>{translation("messages.info.searchableProperties.vendorArticleNumber")}</li>
                    </ul>
                </Dialog.Content>
            </Dialog>
        </Dialog>
    );
}