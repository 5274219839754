import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { cartGuidAtom } from "store/cart/cartGuid";
import useCartService from "hooks/cart/useCartService";
import { cartAtom } from "store/cart/cart";
import customerAtom from "store/customer";
import refreshTriggerAtomFamily from "store/refreshTrigger";

export default function useInitializeCart() {
    const [guid, setGuid] = useRecoilState(cartGuidAtom);
    const cart = useRecoilValue(cartAtom);
    const cartRefreshTrigger = useRecoilValue(refreshTriggerAtomFamily("cart"));
    const customer = useRecoilValue(customerAtom);

    const { createCart, getCart, updateCustomer } = useCartService();

    useEffect(() => {
        if (!guid && cart?.guid) {
            setGuid(cart.guid);
        } else if (!guid) {
            createCart();
        }
    }, [guid]);

    useEffect(() => {
        if (!cart) return;

        if (customer && String(customer.id) !== cart.customerIdentifier) {
            updateCustomer(String(customer.id));
        } else if (!customer && cart.customerIdentifier) {
            updateCustomer(null);
        }
    }, [customer, cart]);

    useEffect(() => {
        if (!Number(cartRefreshTrigger) > 0 || !guid) return;

        getCart();
    }, [cartRefreshTrigger]);
}
