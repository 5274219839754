import { atomFamily } from "recoil";

export const refreshTriggerAtomFamily = atomFamily({
    key: "refreshTriggerAtomFamily",
    default: 1,
    effects: [
        ({ onSet, setSelf }) => {
            onSet((_newValue, oldValue, _isReset) => {
                setSelf(oldValue + 1);
            });
        }
    ]
});