import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEndpoint } from "@divide/ois-react-hooks";
import { useAuthentication } from "@divide/ois-react-components";
import appConfig from "constants/appConfig";
import ProductNotFoundError from "exceptions/ProductNotFoundError";
import { productsUrl } from "helpers/retailApiUrls";
import usePhysicalScanner from "./usePhysicalScanner";

export default function usePhysicalProductScanner(translation, retailApiEnabled) {
    const navigate = useNavigate();

    const { fetchTask, isFetching } = useEndpoint(productsUrl(), useAuthentication);

    const getProductByBarcode = async (barcode) => {
        if (retailApiEnabled !== "true") return;

        try {
            const response = await fetchTask("GET", `?barcodes=${barcode}`, appConfig, false);

            if (response?.data?.length > 0) {
                const product = response.data[0];
                const variant = product.variants.find((variant) => variant.barcode === barcode);

                let url = `/product/${response.data[0].identifier}`;

                if (variant) {
                    url += `?colorId=${variant.colorId}&sizeId=${variant.sizeId}`;

                    if (variant.subSizeId) {
                        url += `&subSizeId=${variant.subSizeId}`;
                    }
                }

                navigate(url);
            } else {
                throw new ProductNotFoundError(`Could not find product with barcode ${barcode}`);
            }
        } catch (e) {
            console.error(e);
            toast.error(translation("messages.warning.noProductFoundWithBarcode", barcode));
        }
    };

    usePhysicalScanner(getProductByBarcode);

    return isFetching;
}
