import { useEffect, useContext } from "react";
import useGetStoredCartAmount from "hooks/general/useGetStoredCartAmount";
import useInitializeCart from "hooks/cart/useInitializeCart";
import useBranchWarning from "hooks/general/useBranchWarning";
import { AppContext } from "parts/App";

export function PrivateRoot({ children }) {
    const { setCartAmount, userBranch, translation } = useContext(AppContext);
    const amount = useGetStoredCartAmount();
    
    useBranchWarning(userBranch, translation);

    useEffect(() => {
        setCartAmount(amount);
    }, [amount]);

    useInitializeCart();

    return <>{children}</>;
}
