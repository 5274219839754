import { toast } from "react-toastify";
import { useSetRecoilState, useRecoilState, useResetRecoilState } from "recoil";
import { useAuthentication } from "@divide/ois-react-components";
import refreshTriggerAtomFamily from "store/refreshTrigger";
import { cartAtom, cartGuidAtom, cartIsFetchingAtom } from "store/cart";
import retailApiClient from "common/retailApiClient";
import appConfig from "constants/appConfig";
import { useState } from "react";
import { useDebounceEffect } from "@divide/ois-react-hooks";

const baseCartUrl = `${appConfig.apis.retailApi.shop.v1}cart`;

export default function useCartService() {
    const [fetching, setFetching] = useRecoilState(cartIsFetchingAtom);
    const [isFetching, setIsFetching] = useState(fetching);

    useDebounceEffect(
        () => {
            setIsFetching(fetching);
        },
        50,
        [fetching]
    );

    const [guid, setGuid] = useRecoilState(cartGuidAtom);
    const resetGuid = useResetRecoilState(cartGuidAtom);
    const [cart, setCart] = useRecoilState(cartAtom);
    const resetCart = useResetRecoilState(cartAtom);
    const setCartRefreshTrigger = useSetRecoilState(refreshTriggerAtomFamily("cart"));

    const { getTokenSilently } = useAuthentication();

    const createCart = async () => {
        const {
            data: { guid }
        } = await performFetch("POST", baseCartUrl);

        resetCart();
        setGuid(guid);
        setCartRefreshTrigger();
    };

    const getCart = async () => {
        if (!guid) return;

        const response = await performFetch("GET", `${baseCartUrl}/${guid}`, {}, { silently: true });

        if (response?.data) {
            setCart(response.data);
        } else {
            reset();
        }
    };

    const updateCart = async (payload = {}) => {
        if (!guid || !cart) return;

        await performFetch("PUT", `${baseCartUrl}/${guid}`, payload);
    };

    const reset = () => {
        resetCart();
        resetGuid();
    };

    const updateCustomer = async (customerIdentifier = null) => {
        if (!cart) return;

        await updateCart({ customerIdentifier });
    };

    const addOrUpdateProduct = async (variantIdentifier = null, quantity = 1, addToCurrentQuantity = false) => {
        if (!cart) return;

        addOrUpdateProducts([{ variantIdentifier, quantity }], addToCurrentQuantity);
    };

    const addOrUpdateProducts = async (lines = [], addToCurrentQuantity = false) => {
        if (!cart || !lines?.length > 0) return;

        let payloadPut = [];
        let payloadPost = [];

        for (const line of lines) {
            const existingLine = cart.lines.find((cartLine) => cartLine.variantIdentifier === line.variantIdentifier);

            if (existingLine) {
                const newQuantity = addToCurrentQuantity ? line.quantity + existingLine.quantity : line.quantity;

                payloadPut.push({ variantIdentifier: line.variantIdentifier, quantity: Math.max(1, newQuantity) });
            } else {
                payloadPost.push({ variantIdentifier: line.variantIdentifier, quantity: Math.max(1, line.quantity), manualDiscount: null });
            }
        }

        if (payloadPut.length > 0) {
            await performFetch("PUT", `${baseCartUrl}/${guid}/lines`, payloadPut);
        }

        if (payloadPost.length > 0) {
            await performFetch("POST", `${baseCartUrl}/${guid}/lines`, payloadPost);
        }

        setCartRefreshTrigger();
    };

    const deleteProduct = async (variantIdentifier = null) => {
        if (!guid || !variantIdentifier) return;

        await performFetch("DELETE", `${baseCartUrl}/${guid}/lines/${variantIdentifier}`);
    };

    const addOrUpdateLineManualDiscount = async (variantIdentifier = null, payload = {}) => {
        if (!guid || !variantIdentifier) return;

        await performFetch("PUT", `${baseCartUrl}/${guid}/lines/${variantIdentifier}/discount`, payload);
    };

    const removeLineManualDiscount = async (variantIdentifier = null) => {
        if (!guid || !variantIdentifier) return;

        await performFetch("DELETE", `${baseCartUrl}/${guid}/lines/${variantIdentifier}/discount`);
    };

    const addOrUpdateManualDiscount = async (payload = {}) => {
        if (!guid || !cart) return;

        await performFetch("PUT", `${baseCartUrl}/${guid}/discount`, payload);

        setCartRefreshTrigger();
    };

    const removeManualDiscount = async () => {
        if (!guid) return;

        await performFetch("DELETE", `${baseCartUrl}/${guid}/discount`);
    };

    const performFetch = async (method = "GET", url = null, payload = {}, options = {}) => {
        const bearerToken = await getTokenSilently();
        if (!bearerToken || !url) return;

        let response;

        try {
            setFetching(true);

            switch (method) {
                case "GET":
                    response = await retailApiClient.get(url, bearerToken);
                    break;
                case "POST":
                    response = await retailApiClient.post(url, payload, bearerToken);
                    break;
                case "PUT":
                    response = await retailApiClient.put(url, payload, bearerToken);
                    break;
                case "DELETE":
                    response = await retailApiClient.delete(url, payload, bearerToken);
                    break;
                default:
                    throw new Error("Invalid method");
            }

            if (method !== "GET") {
                setCartRefreshTrigger();
            }
        } catch (error) {
            if (!options?.silently) {
                toast.error(error.message);
            }
        } finally {
            setFetching(false);
        }

        return response;
    };

    return {
        createCart,
        getCart,
        updateCart,
        reset,
        updateCustomer,
        addOrUpdateProduct,
        deleteProduct,
        addOrUpdateLineManualDiscount,
        removeLineManualDiscount,
        addOrUpdateManualDiscount,
        removeManualDiscount,
        isFetching
    };
}
