import { useEffect } from "react";
import { storage } from "@divide/ois-react-framework/common";
import storageKeys from "constants/storageKeys";

function useValidateCartStorage(dsaConfiguration) {
    useEffect(() => {
        const cart = JSON.parse(storage.get(storageKeys.cart));
        
        let removeCart = false;

        if (dsaConfiguration && cart?.length > 0) {
            if (dsaConfiguration.RetailApiEnabled === "true" && cart.at(0)?.variant?.hasOwnProperty("Id")) {
                removeCart = true;
            } else if (dsaConfiguration.RetailApiEnabled === "false" && cart.at(0)?.variant?.hasOwnProperty("id")) {
                removeCart = true;
            }

            if (removeCart) {
                storage.remove(storageKeys.cart);
                window.location.href = "/";
            }
        }
    }, [dsaConfiguration]);
}
export default useValidateCartStorage;
