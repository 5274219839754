import { lazy, Suspense } from "react";
import { Loader } from "@divide/retailsuite-react-components";
import { sharedRoutes } from "./sharedRoutes";
import { Outlet } from "react-router-dom";
import PrivateRoot from "parts/PrivateRoot";

const ProductOverviewPage = lazy(() => import("pages/ProductOverviewPage"));
const ProductDetailPage = lazy(() => import("pages/ProductDetailPage"));
const ProductPage = lazy(() => import("pages/ProductPage"));
const EanToProductPage = lazy(() => import("pages/EanToProductPage"));
const Step1Page = lazy(() => import("pages/Step1Page"));
const Step2Page = lazy(() => import("pages/Step2Page"));
const Step3Page = lazy(() => import("pages/Step3Page"));
const Step4Page = lazy(() => import("pages/Step4Page"));
const Step5Page = lazy(() => import("pages/Step5Page"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const ModePage = lazy(() => import("pages/ModePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <PrivateRoot><Outlet /></PrivateRoot>,
        children: [
            {
                key: "productOverview",
                index: true,
                inMenu: true,
                matchEnd: true,
                path: "/",
                title: "navigation.collection",
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<Loader fullscreen={true} />}>
                        <ProductOverviewPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "productDetail",
                path: "/product",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductDetailPage />
                    </Suspense>
                ),
                children: []
            },        
            {
                key: "product",
                path: "/product/:identifier",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductPage />
                    </Suspense>
                ),
                children: []
            },        
            {
                key: "eanBarcodeToProduct",
                path: "/ean/:barcode",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <EanToProductPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCart",
                path: "cart",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step1Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCustomers",
                path: "customers",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step2Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutDetails",
                path: "details",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step3Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutOrder",
                path: "order",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step4Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutOrderCompleted",
                path: "order-completed",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step5Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "profile",
                inmenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            {
                path: "mode/:name",
                element: <Suspense fallback={<></>}><ModePage /></Suspense>,
                children: [],
            }
        ],
        ...sharedRoutes
    },
];
