import resolveScopes from "helpers/resolveScopes";

const env = process.env;
const urlParams = new URLSearchParams(window.location.search);

const authenticationOptionsMap = new Map([
    ["auth0", {
        domain: "divide.eu.auth0.com",
        scope: resolveScopes("profile openid email chooseBranch admin get:settings get:endpoints get:addresscompletion get:branches put:branches get:catalog get:customer put:customer post:customer get:inventory get:order post:order get:prices get:inventorymatrix get:configuration get:pickuppoints get:productsets"),
        client_id: env.REACT_APP_AUTH0_CLIENT_ID,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        organization: undefined,
        invitation: urlParams.get("invitation") || ""
    }],
    ["entra", {
        auth: {
            clientId: env.REACT_APP_MSENTRA_CLIENT_ID,
            authority: env.REACT_APP_MSENTRA_AUTHORITY,
            scopes: [],
            redirectUri: window.location.origin
        }
    }]
]);

export default authenticationOptionsMap;